import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.instagram.com/_amityadav/"
        className="home__social-icon" 
        target="_blank" rel="noopener noreferrer" 
        >
          <i class="uil uil-instagram"></i>
        </a>

        <a href="https://twitter.com/yadavamit31" 
        className="home__social-icon" 
        target="_blank" rel="noopener noreferrer" 
        >
          <i class="uil uil-twitter"></i>
        </a>

        <a href="https://github.com/yadavamitt" 
        className="home__social-icon" 
        target="_blank" rel="noopener noreferrer" 
        >
          <i class="uil uil-github"></i>
        </a>
    </div>
  );
};

export default Social;